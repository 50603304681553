<template>
  <div class="error-404">
    <h1 class="error-404__title">
      404
    </h1>
    <h2 class="error-404__text">
      Sorry... we could not find the page you are looking for.
    </h2>
    <router-link
      class="error-404__link"
      to="/"
    >
      Go back to home
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage404'
}
</script>

<style lang="scss" scoped>
.error-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: spacing(4);
  text-align: center;
}

.error-404__title {
  margin-bottom: spacing(4);
}

.error-404__text {
  margin-bottom: spacing(4);
}

.error-404__link {
  color: $blue;
  cursor: pointer;
}
</style>
